import React, {useState} from "react";
import {MDBBtn, MDBIcon, MDBModal, MDBModalBody, MDBModalFooter, MDBModalHeader, MDBSelect, MDBSpinner} from "mdbreact";
import {useFieldArray, useForm, Controller} from "react-hook-form";
import ThirdNest from "./Components/ThirdNest";
import DatePicker from "react-datepicker";
import firebase from "../../firebase";

const CreateNewMeasurement = (props) => {
    const [original, setOriginal] = useState(null);
    const [orgs, setOrgs] = useState([]);
    const [messageOrg, setMessageOrg] = useState(null);
    const [unionShare, setUnionShare] = useState([]);
    const [removeItems, setRemoveItems] = useState([]);
    const [removeProcess, setRemoveProcess] = useState([]);
    const [shareEdit, setShareEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showModalItem, setShowModalItem] = useState(false);
    const [visibleUrl, setVisibleUrl] = useState(true);
    const processData = {
        process: [
            {
                process_name: '',
                analysis_product_name: '',
                items: [
                    {
                        item_name: '',
                        tolerance_lvl_max: "",
                        tolerance_lvl_min: "",
                        unit: '%',
                        range_settings: '上限下限共',
                        graph_display: false,
                        fieldMeasure: {},
                        labMeasure: {},
                        automaticMeasure: {},
                    }
                ],
            }
        ],
    }
    const {register, setError, handleSubmit, control, formState: {errors}, getValues, setValue} = useForm({
        // defaultValues: {}; you can populate the fields by this attribute
        defaultValues: props.cProps.match.params.id ? async () => await loadData() : processData,
    });
    const {fields, append, remove} = useFieldArray({
        control,
        name: "process",
    })

    const loadData = async () => {
        try {
            setLoading(true);
            let fromOrg = "";
            let toOrg = "";
            if (props.userOrg && props.userOrg.reseller && props.org && props.org.buyer) {
                //console.log('logged seller')
                toOrg = props.org.id;
                fromOrg = props.user.organization;
            } else if (props.userOrg && props.userOrg.maker && props.org && props.org.reseller) {
                //console.log('logged maker')
                toOrg = props.org.id;
                fromOrg = props.user.organization;
            } else if (props.userOrg && props.userOrg.reseller && props.org && props.org.maker) {
                //console.log('props maker')
                toOrg = props.org.id;
                //fromOrg = props.user.organization;
            } else if (props.userOrg && props.userOrg.buyer && props.org && props.org.reseller) {
                //console.log('logged buyer')
                //fromOrg = props.org.id;
                toOrg = props.user.organization;
            } else if (props.orgSeller && props.user && props.user.admin) {
                //console.log("props.orgSeller.id", props.orgSeller.id)
                fromOrg = props.orgSeller.id;
            }
            const snap = await props.db.collection('measurement').doc(props.cProps.match.params.id).get();
            const dt = {id: snap.id, process: [], ...snap.data()};
            let checkOrg = dt.measureOrgs.filter(ele => [fromOrg, toOrg].includes(ele));
            if (checkOrg.length === 0) {
                setVisibleUrl(false);
            }
            dt.start_date = dt.start_date.toDate();
            if (props.userOrg && props.userOrg.measureShare) {
                let union = [...new Set([...dt.measureShare, ...props.userOrg.measureShare])];
                let uniquetags = [];
                union.map(d => {
                    if (uniquetags.filter(x => x.organization === d.organization).length === 0) {
                        uniquetags.push({'organization': d.organization, 'name': d.name})
                    }
                });
                setUnionShare(uniquetags);
            }
            let process = await props.db.collection('measurement').doc(props.cProps.match.params.id).collection('process').get();
            let processData = [];
            if (process.docs.length > 0) {
                for (let i = 0; i < process.docs.length; i++) {
                    let doc = process.docs[i];
                    let data = {};
                    data.index = doc.data()['index'];
                    data.process_name = doc.data()['process_name'];
                    data.analysis_product_name = doc.data()['analysis_product_name'];
                    data.id = doc.id;
                    data.items = []
                    processData.push(data)
                }
            }
            processData.sort((d1, d2) => {
                return d1.index - d2.index
            });
            if (processData && processData.length > 0) {
                for (let i = 0; i < processData.length; i++) {
                    let items = await props.db.collection('measurement').doc(props.cProps.match.params.id).collection('process').doc(processData[i].id).collection('items').get();
                    let itemsData = [];
                    if (items.docs.length > 0) {
                        for (let i1 = 0; i1 < items.docs.length; i1++) {
                            let doc = items.docs[i1];
                            let data1 = {};
                            data1.index = doc.data()['index'];
                            data1.item_name = doc.data()['item_name'];
                            data1.tolerance_lvl_max = doc.data()['tolerance_lvl_max'];
                            data1.tolerance_lvl_min = doc.data()['tolerance_lvl_min'];
                            data1.unit = doc.data()['unit'];
                            data1.range_settings = doc.data()['range_settings'];
                            data1.graph_display = doc.data()['graph_display'];
                            data1.id = doc.id;
                            data1.processId = processData[i].id;
                            itemsData.push(data1)
                        }
                    }
                    itemsData.sort((d1, d2) => {
                        return d1.index - d2.index
                    });
                    if (itemsData && itemsData.length > 0) {
                        for (let j = 0; j < itemsData.length; j++) {
                            if (processData[i].id === itemsData[j].processId) {
                                processData[i].items.push(itemsData[j])
                            }
                        }
                    }
                }
            }
            dt.process = processData;
            setShareEdit((dt.fromOrganization !== props.user.organization) ? true : false);
            setOrgs(dt.measureShare);
            setOriginal(dt);
            setLoading(false)
            return dt;
        } catch (e) {
            console.log('error -> ', e);
        }
    }

    const onSubmit = async (data) => {
        if (orgs.length === 0) return setMessageOrg('please select option ');
        let checkItemModal = false;
        data.process.forEach(e=>{
            if(e.items.length === 0) {checkItemModal=true; setShowModalItem(true)};
        })
        if(!checkItemModal) {
            try {
                setLoading(true)
                setMessageOrg(null)
                let measureOrgs = [];
                for (let i = 0; i < orgs.length; i++) {
                    measureOrgs.push(orgs[i].organization);
                }
                data.measureShare = orgs || [];
                if (!props.edit) {
                    data.organization = props.org.id;
                    data.organization_name = props.org.display_name;
                    data.fromOrganization = props.user.organization;
                    data.fromOrganizationName = props.userOrg.display_name;
                    data.buyer = (props.userOrg && props.userOrg.buyer && props.org && props.org.reseller) ? true : false;
                    data.maker = (props.userOrg && props.userOrg.maker && props.org && props.org.reseller) ? true : false;
                }
                data.buyer = props.edit ? original.buyer : data.buyer;
                data.maker = props.edit ? original.maker : data.maker
                let checkOrg = measureOrgs.filter(item => item === data.fromOrganization);
                checkOrg.length === 0 && measureOrgs.push(data.fromOrganization);
                data.measureOrgs = measureOrgs;
                const batch = props.db.batch();
                if (data && data.process.length > 0) {
                    for (let i = 0; i < data.process.length; i++) {
                        for (let j = 0; j < data.process[i].items.length; j++) {
                            data.process[i].items[j].graph_display = (data.process[i].items[j].graph_display === false || data.process[i].items[j].graph_display === 0) ? 0 : 1;
                        }
                    }
                }
                if (props.cProps.match.params.id) {
                    window.scrollTo(0, 0);
                    if (removeItems && removeItems.length > 0) {
                        for (let i = 0; i < removeItems.length; i++) {
                            await Promise.all([
                                props.db.collection('measurement').doc(data.id).collection('process').doc(removeItems[i].processId).collection('items').doc(removeItems[i].itemId).collection('fieldMeasure').get(),
                                props.db.collection('measurement').doc(data.id).collection('process').doc(removeItems[i].processId).collection('items').doc(removeItems[i].itemId).collection('labMeasure').get(),
                                props.db.collection('measurement').doc(data.id).collection('process').doc(removeItems[i].processId).collection('items').doc(removeItems[i].itemId).collection('automaticMeasure').get()

                            ]).then((res) => {
                                console.log('res', res);
                                if (res[0].docs.length > 0 && res[0].docs.length < 500) {
                                    res[0].docs.forEach((doc) => {
                                        batch.delete(doc.ref);
                                    })
                                }
                                if (res[1].docs.length > 0 && res[1].docs.length < 500) {
                                    res[1].docs.forEach((doc) => {
                                        batch.delete(doc.ref);
                                    })
                                }
                                if (res[2].docs.length > 0 && res[2].docs.length < 500) {
                                    res[2].docs.forEach((doc) => {
                                        batch.delete(doc.ref);
                                    })
                                }
                            })
                            for (let j = 0; j < data.process.length; j++) {
                                if (data.process[j].items.filter(e => e.item_name !== undefined).length === 0 || (data.process[j].items.length === data.process[j].items.filter(e => e.processId === undefined).length)) {
                                    await Promise.all([
                                        props.db.collection('measurement').doc(data.id).collection('process').doc(removeItems[i].processId).collection('fieldComments').get(),
                                        props.db.collection('measurement').doc(data.id).collection('process').doc(removeItems[i].processId).collection('labComments').get(),
                                        props.db.collection('measurement').doc(data.id).collection('process').doc(removeItems[i].processId).collection('automaticComments').get()
                                    ]).then((res) => {
                                        if (res[0].docs.length > 0 && res[0].docs.length < 500) {
                                            res[0].docs.forEach((doc) => {
                                                batch.delete(doc.ref);
                                            })
                                        }
                                        if (res[1].docs.length > 0 && res[1].docs.length < 500) {
                                            res[1].docs.forEach((doc) => {
                                                batch.delete(doc.ref);
                                            })
                                        }
                                        if (res[2].docs.length > 0 && res[2].docs.length < 500) {
                                            res[2].docs.forEach((doc) => {
                                                batch.delete(doc.ref);
                                            })
                                        }
                                    })
                                }
                            }

                            //await batch.commit();
                            removeItems[i].processId!==undefined && removeItems[i].itemId && await props.db.collection('measurement').doc(data.id).collection('process').doc(removeItems[i].processId).collection('items').doc(removeItems[i].itemId).delete();

                        }
                    }
                    if (removeProcess && removeProcess.length > 0) {
                        for (let i = 0; i < removeProcess.length; i++) {
                            await Promise.all([
                                props.db.collection('measurement').doc(data.id).collection('process').doc(removeProcess[i]).collection('fieldComments').get(),
                                props.db.collection('measurement').doc(data.id).collection('process').doc(removeProcess[i]).collection('labComments').get(),
                                props.db.collection('measurement').doc(data.id).collection('process').doc(removeProcess[i]).collection('automaticComments').get()
                            ]).then((res) => {
                                if (res[0].docs.length > 0) {
                                    res[0].docs.forEach((doc) => {
                                        batch.delete(doc.ref);
                                    })
                                }
                                if (res[1].docs.length > 0) {
                                    res[1].docs.forEach((doc) => {
                                        batch.delete(doc.ref);
                                    })
                                }
                                if (res[2].docs.length > 0) {
                                    res[2].docs.forEach((doc) => {
                                        batch.delete(doc.ref);
                                    })
                                }
                            })
                            //await batch.commit();
                            await props.db.collection('measurement').doc(data.id).collection('process').doc(removeProcess[i]).delete();
                        }
                    }
                    for (let i = 0; i < data.process.length; i++) {
                        if (data.process[i].id !== undefined && data.process[i].items.length > 0 && data.process[i].items[data.process[i].items.length - 1].id === undefined) {
                            let fieldComment = await props.db.collection('measurement').doc(props.cProps.match.params.id).collection('process').doc(data.process[i].id).collection('fieldComments').get();
                            let fieldCommentData = [];
                            if (fieldComment.docs.length > 0) {
                                for (let fc = 0; fc < fieldComment.docs.length; fc++) {
                                    let doc = fieldComment.docs[fc];
                                    let fcommentData = {};
                                    fcommentData.insertedDate = doc.data()['insertedDate']
                                    fcommentData.value = ''
                                    fcommentData.edit = false
                                    fieldCommentData.push(fcommentData);
                                }
                                // console.log('fieldCommentData', fieldCommentData);
                            }
                            for (let i1 = 1; i1 < data.process[i].items.length; i1++) {
                                if (data.process[i].items[i1].id === undefined) {
                                    data.process[i].items[i1].fieldMeasure = fieldCommentData
                                }
                            }
                            let labComment = await props.db.collection('measurement').doc(props.cProps.match.params.id).collection('process').doc(data.process[i].id).collection('labComments').get();
                            let labCommentData = [];
                            if (labComment.docs.length > 0) {
                                for (let lc = 0; lc < labComment.docs.length; lc++) {
                                    let doc = labComment.docs[lc];
                                    let lcommentData = {};
                                    lcommentData.insertedDate = doc.data()['insertedDate']
                                    lcommentData.value = ''
                                    lcommentData.edit = false
                                    labCommentData.push(lcommentData);
                                }
                                //console.log('labCommentData', labCommentData);
                            }
                            for (let i2 = 1; i2 < data.process[i].items.length; i2++) {
                                if (data.process[i].items[i2].id === undefined) {
                                    data.process[i].items[i2].labMeasure = labCommentData
                                }
                            }
                            let automaticComment = await props.db.collection('measurement').doc(props.cProps.match.params.id).collection('process').doc(data.process[i].id).collection('automaticComments').get();
                            let automaticCommentData = [];
                            if (automaticComment.docs.length > 0) {
                                for (let ac = 0; ac < automaticComment.docs.length; ac++) {
                                    let doc = automaticComment.docs[ac];
                                    let acommentData = {};
                                    acommentData.insertedDate = doc.data()['insertedDate']
                                    acommentData.value = ''
                                    acommentData.edit = false
                                    automaticCommentData.push(acommentData);
                                }
                                // console.log('automaticCommentData', automaticCommentData);
                            }
                            for (let i3 = 1; i3 < data.process[i].items.length; i3++) {
                                if (data.process[i].items[i3].id === undefined) {
                                    data.process[i].items[i3].automaticMeasure = automaticCommentData
                                }
                            }

                        }
                    }
                    await props.db.collection('measurement').doc(data.id).update(
                        {
                            name: data.name,
                            start_date: data.start_date,
                            measureShare: data.measureShare,
                            organization: data.organization,
                            organization_name: data.organization_name,
                            measureOrgs: data.measureOrgs,
                        })

                    for (let i = 0; i < data.process.length; i++) {
                        let processRef;
                        if (data.process && data.process[i].id !== undefined) {
                            await props.db.collection('measurement').doc(data.id).collection('process').doc(data.process[i].id).update({
                                index: i,
                                process_name: data.process[i].process_name,
                                analysis_product_name: data.process[i].analysis_product_name
                            });
                        } else {
                            processRef = await props.db.collection('measurement').doc(data.id).collection('process').doc();
                            await processRef.set({
                                index: i,
                                process_name: data.process[i].process_name,
                                analysis_product_name: data.process[i].analysis_product_name,
                            }, {merge: true});
                        }
                        for (let j = 0; j < data.process[i].items.length; j++) {
                            data.process[i].items[j].graph_display = (data.process[i].items[j].graph_display === false || data.process[i].items[j].graph_display === 0) ? 0 : 1;

                            if (data.process && data.process[i].id !== undefined && data.process[i].items && data.process[i].items[j].id !== undefined) {
                                await props.db.collection('measurement').doc(data.id).collection('process').doc(data.process[i].id).collection('items').doc(data.process[i].items[j].id).update({
                                    index: j,
                                    item_name: data.process[i].items[j].item_name,
                                    tolerance_lvl_max: data.process[i].items[j].tolerance_lvl_max,
                                    tolerance_lvl_min: data.process[i].items[j].tolerance_lvl_min,
                                    unit: data.process[i].items[j].unit,
                                    range_settings: data.process[i].items[j].range_settings,
                                    graph_display: data.process[i].items[j].graph_display
                                });
                            } else if (data.process && data.process[i].id !== undefined && data.process[i].items && data.process[i].items[j].id === undefined && data.process[i].items[j].item_name !== undefined) {
                                let items = await props.db.collection('measurement').doc(data.id).collection('process').doc(data.process[i].id).collection('items').doc();
                                await items.set({
                                    index: j,
                                    item_name: data.process[i].items[j].item_name,
                                    tolerance_lvl_max: data.process[i].items[j].tolerance_lvl_max,
                                    tolerance_lvl_min: data.process[i].items[j].tolerance_lvl_min,
                                    unit: data.process[i].items[j].unit,
                                    range_settings: data.process[i].items[j].range_settings,
                                    graph_display: data.process[i].items[j].graph_display,
                                }, {merge: true});
                                if (data.process[i].items[j].fieldMeasure !== undefined && data.process[i].items[j].fieldMeasure && data.process[i].items[j].fieldMeasure.length > 0 ) {
                                    //console.log('items', items.id);
                                    //console.log('data.process[i].items[j].fieldMeasure.length', data.process[i].items[j].fieldMeasure.length);
                                    data.process[i].items[j].fieldMeasure.forEach((doc) => {
                                        //console.log(doc);
                                        let fieldRef = props.db.collection('measurement').doc(data.id).collection('process').doc(data.process[i].id).collection('items').doc(items.id).collection('fieldMeasure').doc();
                                        if (data.process[i].items[j].fieldMeasure.length > 0 && data.process[i].items[j].fieldMeasure.length > 500) {
                                            fieldRef.set({
                                                insertedDate: doc.insertedDate,
                                                value: doc.value,
                                                edit: doc.edit
                                            }, {merge: true});
                                        } else {
                                            batch.set(fieldRef, doc)
                                        }
                                    });
                                }
                                if (data.process[i].items[j].labMeasure !== undefined && data.process[i].items[j].labMeasure && data.process[i].items[j].labMeasure.length > 0) {
                                    //console.log('items1', items.id);
                                    //console.log('data.process[i].items[j].labMeasure.length', data.process[i].items[j].labMeasure.length);
                                    data.process[i].items[j].labMeasure.forEach((doc) => {
                                        //console.log(doc);
                                        let labRef = props.db.collection('measurement').doc(data.id).collection('process').doc(data.process[i].id).collection('items').doc(items.id).collection('labMeasure').doc();
                                        if (data.process[i].items[j].labMeasure.length > 0 && data.process[i].items[j].labMeasure.length > 500) {
                                            labRef.set({
                                                insertedDate: doc.insertedDate,
                                                value: doc.value,
                                                edit: doc.edit
                                            }, {merge: true});
                                        } else {
                                            batch.set(labRef, doc)
                                        }
                                    });
                                }
                                if (data.process[i].items[j].automaticMeasure !== undefined && data.process[i].items[j].automaticMeasure && data.process[i].items[j].automaticMeasure.length > 0) {
                                    //console.log('items1', items.id);
                                    //console.log('data.process[i].items[j].automaticMeasure.length', data.process[i].items[j].automaticMeasure.length);
                                    data.process[i].items[j].automaticMeasure.forEach((doc) => {
                                        //console.log(doc);
                                        let autoRef = props.db.collection('measurement').doc(data.id).collection('process').doc(data.process[i].id).collection('items').doc(items.id).collection('automaticMeasure').doc();
                                        if (data.process[i].items[j].fieldMeasure.length > 0 && data.process[i].items[j].fieldMeasure.length > 500) {
                                            autoRef.set({
                                                insertedDate: doc.insertedDate,
                                                value: doc.value,
                                                edit: doc.edit
                                            }, {merge: true});
                                        } else {
                                            batch.set(autoRef, doc)
                                        }
                                    });
                                }
                            } else if (data.process && data.process[i].id === undefined && data.process[i].items && data.process[i].items[j].id === undefined) {
                                let items = await props.db.collection('measurement').doc(data.id).collection('process').doc(processRef.id).collection('items').doc();
                                await items.set({
                                    index: j,
                                    item_name: data.process[i].items[j].item_name,
                                    tolerance_lvl_max: data.process[i].items[j].tolerance_lvl_max,
                                    tolerance_lvl_min: data.process[i].items[j].tolerance_lvl_min,
                                    unit: data.process[i].items[j].unit,
                                    range_settings: data.process[i].items[j].range_settings,
                                    graph_display: data.process[i].items[j].graph_display,
                                }, {merge: true});
                            }
                        }
                    }
                    await batch.commit().then(function () {
                        console.log('field Done.')
                    }).catch(err => console.log(`There field was an error: ${err}`))
                    //insert to log
                    try {
                        let data1 = original;
                        if (removeProcess.length > 0) {
                            for (let i = 0; i < removeProcess.length; i++) {
                                data1 = data1.process.filter(item => item.id !== removeProcess[i]);
                            }
                            //.filter(item => item.id === doc.id);
                        }
                        data1.measureShare && delete data1.measureShare;
                        data1.measureOrgs && delete data1.measureOrgs;
                        let data2 = data;
                        data2.measureShare && delete data2.measureShare;
                        data2.measureOrgs && delete data2.measureOrgs;
                        for (let i = 0; i < data1.process.length; i++) {
                            data1.process[i].id && delete data1.process[i].id;
                            data1.process[i].index && delete data1.process[i].index;

                            for (let j = 0; j < data1.process[i].items.length; j++) {
                                data1.process[i].items[j].id !== undefined && delete data1.process[i].items[j].id;
                                data1.process[i].items[j].index !== undefined && delete data1.process[i].items[j].index;
                            }
                        }
                        for (let i = 0; i < data2.process.length; i++) {
                            data2.process[i].id && delete data2.process[i].id;
                            data2.process[i].index && delete data2.process[i].index;
                            for (let j = 0; j < data2.process[i].items.length; j++) {
                                data2.process[i].items[j].id !== undefined && delete data2.process[i].items[j].id;
                                data2.process[i].items[j].index !== undefined && delete data2.process[i].items[j].index;
                            }
                        }
                        const logs = compareObjects(data1, data2);
                        //  console.log('logs', logs);
                        if (logs && logs.length > 0) {
                            const obj = {
                                measurement_doc_id: props.cProps.match.params.id,
                                logs: firebase.firestore.FieldValue.arrayUnion(...logs),
                            }
                            const measurementLogs = await props.db.collection('measurement_logs').doc(props.cProps.match.params.id);
                            await measurementLogs.set(obj, {merge: true});
                        }
                    } catch (e) {
                        console.log('measurement log insert error', e)
                    }
                } else {
                    const measurement = await props.db.collection('measurement').doc();
                    await measurement.set({
                        fieldUpdate: {},
                        labUpdate: {},
                        automaticUpdate: {},
                        name: data.name,
                        start_date: data.start_date,
                        measureShare: data.measureShare,
                        organization: data.organization,
                        organization_name: data.organization_name,
                        measureOrgs: data.measureOrgs,
                        created_date: new Date(),
                        created_user: props.user.id,
                        created_user_firstName: props.user.first_name,
                        created_user_lastName: props.user.last_name,
                        fromOrganization: data.fromOrganization,
                        fromOrganizationName: data.fromOrganizationName,
                        buyer: data.buyer,
                    });
                    if (data && data.process.length > 0) {
                        for (let i = 0; i < data.process.length; i++) {
                            let process = await props.db.collection('measurement').doc(measurement.id).collection('process').doc();
                            await process.set({
                                index: i,
                                process_name: data.process[i].process_name,
                                analysis_product_name: data.process[i].analysis_product_name,
                            }, {merge: true});
                            for (let j = 0; j < data.process[i].items.length; j++) {
                                let items = await props.db.collection('measurement').doc(measurement.id).collection('process').doc(process.id).collection('items').doc();
                                //data.process[i].items[j].graph_display = (data.process[i].items[j].graph_display === false || data.process[i].items[j].graph_display === 0) ? 0 : 1;
                                await items.set({
                                    index: j,
                                    item_name: data.process[i].items[j].item_name,
                                    tolerance_lvl_max: data.process[i].items[j].tolerance_lvl_max,
                                    tolerance_lvl_min: data.process[i].items[j].tolerance_lvl_min,
                                    unit: data.process[i].items[j].unit,
                                    range_settings: data.process[i].items[j].range_settings,
                                    graph_display: data.process[i].items[j].graph_display,
                                }, {merge: true});
                            }
                        }

                    }


                }
                setLoading(false)
                props.cProps.history.push('/measurement');
            } catch (e) {
                console.log('error', e);
            }
        }
    }

    const compareObjects = (original, updated) => {
        const differences = [];

        function recursiveCompare(obj1, obj2, path = '') {
            for (const key in obj1) {
                const currentPath = path ? `${path}.${key}` : key;

                if (obj1[key] instanceof Object && obj2[key] instanceof Object) {
                    recursiveCompare(obj1[key], obj2[key], currentPath);
                } else {
                    if (obj1[key] !== obj2[key]) {
                        differences.push({
                            field_name: key,
                            field_path: currentPath,
                            before: obj1[key],
                            after: obj2[key],
                            updated_user: props.user.id,
                            update_date: new Date()
                        });
                    }
                }
            }
        }

        recursiveCompare(original, updated);

        return differences;
    }

    const handleChangeOrg = (e) => {
        //console.log('handleChangeOrg', e);
        let array = [];
        if (props.userOrg && props.userOrg.measureShare) {
            for (let i = 0; i < e.length; i++) {
                array.push(props.userOrg.measureShare.filter(item => item.organization === e[i])[0]);
            }
        }
        setOrgs(array);
    }
    const handleChangeOrgEdit = (e) => {
        //console.log('handleChangeOrg', e);
        let array = [];
        if (unionShare && unionShare.length > 0) {
            for (let i = 0; i < e.length; i++) {
                array.push(unionShare.filter(item => item.organization === e[i])[0]);
            }
        }
        setOrgs(array);
    }
    return (
        <div>
            {visibleUrl &&
                <div>
                    <MDBModal isOpen={showModalItem} toggle={()=>{ setShowModalItem(!setShowModalItem)}}>
                        <MDBModalBody style={{color: '#212529', fontWeight: '500', padding: 30}}>
                            管理項目が設定されていません
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn onClick={()=>{ setShowModalItem(!setShowModalItem)}} color="secondary">キャンセル</MDBBtn>
                        </MDBModalFooter>
                    </MDBModal>
                    <div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div style={{flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                <div style={{flex: 0.5}}>
                                    {props.cProps.match.params.id ? <h1 style={styles.title}>測定データを編集</h1> :
                                        <h1 style={styles.title}>測定データ新規登録</h1>}
                                </div>
                                <div style={{flex: 0.5}}>
                                    <div style={{
                                        flex: 1,
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        height: 46,
                                        alignItems: "center"
                                    }}>
                                <span style={{
                                    marginTop: props.edit ? 0 : 5,
                                    flex: 0.3,
                                    textAlign: 'right'
                                }}>{'共有先事業所：'}</span>
                                        <div style={{flex: 0.7}}>
                                            {
                                                props.userOrg && props.userOrg.measureShare && !props.edit &&
                                                <MDBSelect selectAll={true} className={'cart-select custom-select'}
                                                           multiple={true} options={props.userOrg.measureShare.map((item) => (
                                                    {
                                                        value: item.organization,
                                                        text: item.name,
                                                        checked: (orgs && orgs.filter(ele => ele.organization === item.organization)[0]) ? true : false
                                                    }
                                                ))}
                                                           getValue={(e) => e.length !== orgs.length && (e.map((item) => (orgs.filter(ele => ele.organization === item)))) && orgs.filter(ele => ([e].includes(ele.organization))) && handleChangeOrg(e)}
                                                />

                                            }
                                            {
                                                unionShare && unionShare.length > 0 && props.edit &&
                                                <MDBSelect selectAll={true} className={'cart-select custom-select'}
                                                           multiple={true} options={unionShare.map((item) => (
                                                    {
                                                        value: item.organization,
                                                        text: item.name,
                                                        checked: (orgs && orgs.filter(ele => ele.organization === item.organization)[0]) ? true : false,
                                                        disabled: shareEdit
                                                    }
                                                ))}
                                                           getValue={(e) => e.length !== orgs.length && (e.map((item) => (orgs.filter(ele => ele.organization === item)))) && orgs.filter(ele => ([e].includes(ele.organization))) && handleChangeOrgEdit(e)}
                                                />

                                            }
                                        </div>

                                    </div>
                                    {orgs.length === 0 && messageOrg && <span style={{
                                        color: 'red',
                                        marginLeft: 160,
                                        marginTop: 20,
                                        position: 'absolute'
                                    }}>{messageOrg}</span>}

                                    <div style={{
                                        flex: 1,
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        height: 46,
                                        alignItems: "flex-start",
                                        marginTop: 40
                                    }}>
                                        <span style={{flex: 0.3, textAlign: 'right'}}>共有設定：</span>
                                        <div style={{flex: 0.7}}>
                                            {orgs && orgs.length > 0 ? orgs.map((item, index) => (
                                                <div>{item.name}</div>
                                            )) : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                    <label style={{width: '100px'}}>開始年月日</label>
                                    <Controller rules={{required: true}} control={control} name="start_date"
                                                render={({field: {onChange, onBlur, value, ref}}) => {
                                                    return (
                                                        <DatePicker
                                                            selected={value}
                                                            className={'form-control'}
                                                            dateFormat="yyyy/MM/dd"
                                                            onChange={onChange}
                                                        />
                                                    )
                                                }}>
                                    </Controller>
                                </div>
                                {errors.start_date && <span style={{color: 'red', marginLeft: 100}}>この項目は必須です</span>}
                                <div style={{
                                    paddingTop: '15px',
                                    paddingBottom: '15px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    columnGap: '10px'
                                }}>
                                    <label style={{width: '100px'}}>名称</label>
                                    <input className="form-control" type="text" {...register("name", {required: true})}/>
                                </div>
                                {errors.name && <span style={{color: 'red', marginLeft: 100}}>この項目は必須です</span>}
                            </div>
                            <div>
                                {loading && <div className="d-flex justify-content-center mt-5"><MDBSpinner/></div>}
                                <div>
                                    {fields.map((item, index) => (
                                        <div style={{paddingTop: '15px'}} key={item.id}>
                                            <div style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                padding: '10px 10px',
                                                background: 'dimgray',
                                                color: '#FFF'
                                            }}>
                                                <span>工程 {index + 1}</span>
                                                <a type="button" onClick={() => {
                                                    //console.log('proc click', getValues(`process[${index}].id`));
                                                    getValues(`process[${index}].id`) !== undefined && removeProcess.push(getValues(`process[${index}].id`))
                                                    remove(index);
                                                }}
                                                   style={{marginRight: '10px'}}><MDBIcon className='ms-1' icon='trash'
                                                                                          size='lg'/></a>
                                            </div>
                                            <div>
                                                <div style={{
                                                    paddingTop: '15px',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    columnGap: '10px'
                                                }}>
                                                    <div style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                        columnGap: '10px'
                                                    }}>
                                                        <label style={{width: '100px'}}>工程名称</label>
                                                        <input
                                                            className="form-control" {...register(`process.${index}.process_name`, {required: true})}/>
                                                    </div>
                                                    <div style={{
                                                        paddingTop: '15px',
                                                        paddingBottom: '15px',
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                        columnGap: '10px'
                                                    }}>
                                                        <label style={{width: '100px'}}>分析商品名</label>
                                                        <input
                                                            className="form-control" {...register(`process.${index}.analysis_product_name`)}/>
                                                    </div>
                                                </div>
                                                <ThirdNest nestIndex={index} {...{control, register}}
                                                           processItems={processData.process[0].items[0]} edit={props.edit}
                                                           history={props.edit ? props.cProps.history : false}
                                                           measurementId={props.cProps.match.params.id} errors={errors}
                                                           getValues={getValues} setValue={setValue} deleteItems={(e) => {
                                                    removeItems.push(e);
                                                    //setRemoveItems(prevState => ({...prevState, ...e}));
                                                }}/>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div>
                                <MDBBtn color="secondary"
                                        onClick={() => props.cProps.history.push('/measurement')}>キャンセル</MDBBtn>
                                <MDBBtn type="button" onClick={() => append(processData.process[0])}>工程を追加する</MDBBtn>
                                {/*<MDBBtn>プレビュー</MDBBtn>*/}
                                <MDBBtn type="submit">登録</MDBBtn>
                            </div>
                        </form>
                    </div>
                </div>
            }
        </div>
    )
}
const styles = {
    title: {
        fontFamily: 'Meiryo.ttf',
        textAlign: 'left',
        color: '#212529',
        fontSize: '32px',
        fontWeight: 'normal',
        opacity: 1,
        letterSpacing: '0'
    },
    caption: {
        fontFamily: 'Meiryo.ttf',
        textAlign: 'left',
        color: '#212529',
        fontSize: '16px',
        fontWeight: 'normal',
        opacity: 1,
        letterSpacing: '0'
    }
}
export default CreateNewMeasurement;